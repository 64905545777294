import React from 'react';
import QrScanner from 'qr-scanner';
// import settings from '../../../settings';

export default function({children,setQrCodeData}) {

	const refs	=	{
		video							:	React.useRef(null),
		wrapper							:	React.useRef(null),
		qr_code_value					:	React.useRef(null),
		qr_code_data_parameter_value	:	React.useRef(null),
	};

	const styles	=	{
		pre	:	{
			color	:	'Gold',
			margin	:	0,
		},
		video	:	{
			boxShadow	:	'0px 0px 10px #444',
			maxWidth	:	'480px',
			width		:	'100%',
		},
	};

	function enableScanner(){

		// handleQrCodeCapture(settings.example_values.qr_code_scan);	return false;	//	Debugging Only

		console.log('Scanner: Initializing');
		const qrScanner = new QrScanner(refs.video.current,handleQrCodeCapture);

		console.log('Scanner: Starting');
		qrScanner.start().then(function(){
			console.log('Scanner: Did Start');
		}).catch(function(error){
			console.log('Scanner: Failed to start with error',error);
			alert(error);
		});
	}

	function handleQrCodeCapture(result){

		//	Throttle Capture (Debounce)
		if( window ){
			if( !window.last_qr_capture ){
				window.last_qr_capture	=	Date.now();
				//	proceed
			}else{
				const four_seconds_ago	=	Date.now()-4000;
				if( four_seconds_ago < window.last_qr_capture ){
					console.log('Throttled capture');
					return false;
				}
				window.last_qr_capture	=	Date.now();
			}//ifelse
		}

		//	Normal Execution
		console.log('QR Code Value:',result);
		alert('📸 QR Code value captured. 👍');
		refs.qr_code_value.current.innerHTML	=	result;

		const url	=	new URL(result);

		switch( url.protocol ){
			case 'otpauth-migration:':
				//	All good; Recognized Google Authenticator format that we can put into a Google Procol Buffer.
				refs.qr_code_data_parameter_value.current.innerHTML	=	url.searchParams.get('data');
				setQrCodeData( url.searchParams.get('data') );	//	Pass State to parent
				break;
			default:
				alert("⚠️ Your QR Code isn't a Google Authenticator export so it's not formatted as a Google Protocol Buffer.\n\nNonetheless, we put your QR Code's value below. If the value shows a 'secret', you might be able to use that in Step 4.");
		}

		refs.wrapper.current.style.display	=	'block';
	}

	return(
		<div>
			<div className="text-center">
				<video ref={refs.video} style={styles.video}></video>
				<div>
					<button type="button" className="btn btn-primary m-auto" onClick={enableScanner}>👉 Enable QR Code Scanner 👈</button>
					<p className="mt-3"><small className="form-text text-muted">
						<i>Tips: Hold steady, move your QR code closer/further, make sure this camera can see the entire QR code & is in-focus.</i>
					</small></p>
				</div>
			</div>
			<div ref={refs.wrapper} Style="display:none;">
				<hr />
                <table className="table table-dark">
                    <caption>QR Code Value</caption>
					<tbody>
						<tr>
							<th>QR Code Value</th>
							<td><pre style={styles.pre} ref={refs.qr_code_value}></pre></td>
							<td><pre style={styles.pre} ref={refs.qr_code_data_parameter_value}></pre></td>
						</tr>
						<tr>
                        	<th>Data Parameter</th>
							<td><pre style={styles.pre} ref={refs.qr_code_data_parameter_value}></pre></td>
						</tr>
                    </tbody>
                </table>
            </div>
		</div>
	);

};
